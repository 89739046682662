@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* You can add global styles to this file, and also import other style files */
:root {
  --ultra-marine: #2a14ae;
  --white: #ffffff;
}

$ultra-marine: #2a14ae;
$ultra-marine-hover: #4848fe;
$white: #ffffff;
$danger-red: #dc3545;

:host {
  // background-color: #4848FE;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.confirm-password-body {
  background-color: $white;
  border-width: 3px;
  border-radius: 15px;
  //#2A14AE;
  margin: auto;
  // width: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    max-width: 70%;
  }

  @media screen and (min-width: 992px) {
    max-width: 40%;
  }
}

.confirm-password-card-body {
  @media screen and (max-width: 768px) {
    margin: 0.5em;
  }

  @media screen and (min-width: 768px) {
    margin: 2em 1em;
  }

  @media screen and (min-width: 992px) {
    margin: 3em 2em;
  }
}

.gs-logo {
  margin-bottom: 2rem;
}

.button-flow {
  display: block;
  width: 100%;
  background: $ultra-marine;
  border-radius: 39px;
  border: none;
  outline: none;
  overflow: hidden;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  cursor: pointer;
}

.button-flow:hover {
  background: $ultra-marine-hover;
}

.button-flow:active {
  background: $ultra-marine-hover;
}

.button-flow-text {
  display: inline-flex;
  align-items: center;
  padding: 0 5em 0 6.5em;
  color: $white;
  height: 100%;
}

.password-expression-validation-text {
  font-size: 0.75em !important;
  color: $danger-red;
}

.required-asterisk {
  color: $danger-red;
  margin-left: 3px;
}

table {
  width: 100%;
  font-size: 0.9rem;
}

.gs-btn-cmd {
  margin: 2px;
}

.tooltip {
  font-size: small;
}

.tooltip-inner {
  background-color: $ultra-marine;
  color: $white;
}

.tooltip-arrow{
  color: $ultra-marine;
}

.tooltip.bs-tooltip-auto .tooltip-arrow::before {
  border-right-color: $ultra-marine !important;
  border-left-color: $ultra-marine !important;
}

.gs-modal-header {
  background-color: #1cc88a;
  color: white;
}

.gs-modal-header-secondary {
  background-color: $ultra-marine;
  color: white;
}

.cdk-overlay-container{
  z-index: 1200 !important;
}
